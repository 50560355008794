// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBdpZbsPI29wI-tqKKVxiDc97tdU1Wjj8o",
  authDomain: "wewantitthatway-6df14.firebaseapp.com",
  projectId: "wewantitthatway-6df14",
  storageBucket: "wewantitthatway-6df14.appspot.com",
  messagingSenderId: "488604619682",
  appId: "1:488604619682:web:0f04eed52c2a21a4be58fb",
  measurementId: "G-V53W5JPHJ3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { auth, db };
