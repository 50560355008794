import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import Navbar from "../../components/navbar/navbar";
import { useParams } from "react-router-dom";
import { Modal } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import {
  Timestamp,
  doc,
  getDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserContext from "../../UserContext";
import { YouTubeEmbed } from "react-social-media-embed";
import Swal from "sweetalert2";
import "./communitychallenge.css";

function CommunityChallenges() {
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [videos, setVideos] = useState([]);
  const [top10, setTop10Videos] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [submittedUrl, setSubmittedUrl] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [refreshVideos, setRefreshVideos] = useState(0);
  const { id } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const user = React.useContext(UserContext);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initialize Algolia
  const client = algoliasearch(
    "7RAOMHXUV5",
    "cdf0e2f251638a534a1edb9176c5812f"
  );
  const index = client.initIndex("Videos");
  const Top10 = client.initIndex("VideosTop10");

  // Open a modal with the video player for the given video id
  const openModal = (videoId) => {
    setModalIsOpen(true);
    setCurrentVideo(videoId);
  };

  // Close the modal and stop the video playback
  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  useEffect(() => {
    const fetchChallengeDetails = async () => {
      const docRef = doc(db, "Challenges", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setChallengeDetails(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    // Fetch videos from the database
    const fetchVideos = async () => {
      const q = query(collection(db, "Videos"), where("challengeId", "==", id));
      const querySnapshot = await getDocs(q);
      const videos = querySnapshot.docs.map((doc) => {
        const video = doc.data();
        video.id = doc.id;
        video.votedByUser =
          user && video.voters && video.voters.includes(user.uid);
        return video;
      });
      setVideos(videos);
    };

    // Fetch the top 10 videos from Algolia
    const fetchTop10Videos = () => {
      Top10.search("", { hitsPerPage: 10 }, (err, { hits } = {}) => {
        if (err) throw err;

        // Use the results
        setTop10Videos(hits);
      });
    };

    fetchChallengeDetails();
    fetchVideos();
    fetchTop10Videos();
  }, [id, refreshVideos]);

  useEffect(() => {
    if (challengeDetails) {
      const countdown = setInterval(() => {
        const now = new Date().getTime();
        const remaining = challengeDetails.endTime - now;
        setTimeRemaining(remaining);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [challengeDetails]);

  if (!challengeDetails) {
    return <div>Loading...</div>;
  }

  async function handleVote(videoId) {
    if (!user) {
      Swal.fire("Please log in to vote", "", "info");
      return;
    }

    const videoRef = doc(db, "Videos", videoId);

    // Get the current votes for the video
    const videoSnapshot = await getDoc(videoRef);
    const currentVotes = videoSnapshot.data().votes || 0;
    const voters = videoSnapshot.data().voters || [];

    // Determine whether the user is voting or unvoting
    const isUnvoting = voters.includes(user.uid);
    const voteChange = isUnvoting ? -1 : 1;

    // Update the votes for the video
    await updateDoc(videoRef, {
      votes: currentVotes + voteChange,
      voters: isUnvoting ? arrayRemove(user.uid) : arrayUnion(user.uid),
    });

    setRefreshVideos(refreshVideos + 1);

    // Show a Swal message based on whether the user is voting or unvoting
    const message = isUnvoting
      ? "You have removed your vote!"
      : "Thanks for voting, your vote has been counted!";
    Swal.fire(message, "", "success");
  }

  const handleFollow = () => {
    // Follow the challenge or user
  };

  const handleShare = () => {
    // Share the challenge
  };

  if (!challengeDetails) {
    return <div>Loading...</div>;
  }

  const top10Videos = videos.slice(0, 10);

  const handleSubmission = async () => {
    const { value: url } = await Swal.fire({
      title: "Enter the YouTube link for your submission",
      input: "url",
      inputPlaceholder: "Enter your YouTube video link here",
      showCancelButton: true,
      inputValidator: (value) => {
        const regex =
          /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        if (!regex.test(value)) {
          return "Please enter a valid YouTube URL!";
        }
      },
    });

    if (url) {
      // Store the submitted URL in state
      setSubmittedUrl(url);

      // Add the URL and other information to the Videos collection in Firebase
      try {
        const docRef = await addDoc(collection(db, "Videos"), {
          url: url,
          challengeName: challengeDetails.challengeName,
          challengeId: id,
          Timestamp: Timestamp.now(),
          user: user,
        });

        console.log("Document written with ID: ", docRef.id);

        // Update the user with details of the video upload
        const userRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          videosSubmitted: arrayUnion({
            url: url,
            challengeName: challengeDetails.challengeName,
            challengeId: id,
            Timestamp: Timestamp.now(),
          }),
        });

        // Display a success alert
        Swal.fire(
          "Success",
          "Your video has been submitted successfully!",
          "success"
        );

        setRefreshVideos(refreshVideos + 1);
      } catch (error) {
        console.error("Error adding document: ", error);

        // Display a failure alert
        Swal.fire(
          "Error",
          "There was an error submitting your video. Please try again.",
          "error"
        );
      }
    }
  };

  const handleFlag = (video) => {
    Swal.fire({
      title: "Report Video",
      text: "Please specify the reason for flagging this video:",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: async (flagReason) => {
        try {
          const docRef = await addDoc(collection(db, "Flags"), {
            videoID: video.id,
            reason: flagReason,
            user: user,
          });
          return docRef.id;
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: `Reported!`,
          icon: "success",
        });
      }
    });
  };

  return (
    <>
      <Navbar />
      <Box className="challengeCardBox">
        <div className="melodymash-container">
          <div className="community-content">
            <h1 className="challenge-name">{challengeDetails.challengeName}</h1>
            <p style={{ color: "#EAB308", fontFamily: "roboto" }}>
              {challengeDetails.challengeDescription}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2 className="challenge-details">
                {challengeDetails.description}
              </h2>
              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  width: "100%",
                }}
              />
              <br />
              <br />

              <Typography variant="h6" className="challengeCardRequirements">
                Original Song
              </Typography>

              {challengeDetails.song && challengeDetails.song.id && (
                <iframe
                  style={{
                    borderRadius: "5px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  src={`https://open.spotify.com/embed/track/${challengeDetails.song.id}`}
                  width="100%"
                  height="152"
                  frameBorder="0"
                  allowFullScreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                ></iframe>
              )}

              <Typography variant="h4" className="challengeCardRequirements">
                Criteria
              </Typography>

              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  width: "90%",
                }}
              />
              <Typography variant="h6" className="challengeCardRequirements">
                Instruments
              </Typography>
              <div className="challengeChips">
                {challengeDetails.instruments &&
                challengeDetails.instruments.length > 0 ? (
                  Array.isArray(challengeDetails.instruments) &&
                  challengeDetails.instruments.map((instrument) => (
                    <Chip
                      key={instrument}
                      label={instrument}
                      deleteIcon={<DeleteIcon />}
                      sx={{
                        bgcolor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                      }}
                    />
                  ))
                ) : (
                  <Chip
                    label="Any Instrument"
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                )}
              </div>

              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  width: "90%",
                }}
              />

              <Typography variant="h6" className="challengeCardRequirements">
                Style
              </Typography>
              <div className="challengeChips">
                {challengeDetails.styles &&
                challengeDetails.styles.length > 0 ? (
                  Array.isArray(challengeDetails.styles) &&
                  challengeDetails.styles.map((style) => (
                    <Chip
                      key={style}
                      label={style}
                      deleteIcon={<DeleteIcon />}
                      sx={{
                        bgcolor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                      }}
                    />
                  ))
                ) : (
                  <Chip
                    label="Any Style"
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                )}
              </div>

              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  width: "90%",
                }}
              />
              <Typography variant="h6" className="challengeCardRequirements">
                Conditions
              </Typography>
              <div className="challengeChips">
                {challengeDetails.conditions &&
                challengeDetails.conditions.length > 0 ? (
                  Array.isArray(challengeDetails.conditions) &&
                  challengeDetails.conditions.map((condition) => (
                    <Chip
                      key={condition}
                      label={condition}
                      deleteIcon={<DeleteIcon />}
                      sx={{
                        bgcolor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                      }}
                    />
                  ))
                ) : (
                  <Chip
                    label="Any Condition"
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                )}
              </div>
              <hr
                style={{
                  color: "#000000",
                  backgroundColor: "#000000",
                  height: 1,
                  borderColor: "#000000",
                  width: "90%",
                }}
              />
            </div>
            <Typography variant="h6" className="challengeCardRequirements">
              Upload your challenge to your YouTube channel and submit it here!
            </Typography>
            <button
              style={{ color: "#1E3A8A" }}
              className="button submit-button"
              onClick={handleSubmission}
            >
              Submit
            </button>
          </div>
          <div className="leaderboard-heading">
            <h2>Top 10 Leaderboard</h2>
          </div>
          <div className="leaderboard-container">
            {top10Videos && top10Videos.length > 0 ? (
              top10Videos
                .sort((a, b) => b.votes - a.votes)
                .slice(0, 10)
                .map((video, index) => {
                  return (
                    <div key={video.id} className="video-card">
                      <div className="video-embed-container">
                        <YouTubeEmbed
                          className="video-embed"
                          url={video.url}
                          width={windowWidth > 600 ? 640 : 250}
                          height={windowWidth > 600 ? 360 : 200}
                        />
                      </div>
                      <div className="video-info">
                        <div className="rank">#{index + 1}</div>
                        <div>
                          <button
                            className="vote-button"
                            onClick={() => {
                              const voteChange = video.votedByUser ? -1 : 1;
                              handleVote(video.id, voteChange);
                              video.votedByUser = !video.votedByUser;
                            }}
                          >
                            {video.votedByUser ? "Voted!" : "Vote"}
                          </button>
                          <span className="vote-count">
                            {video.votes || 0} vote(s)
                          </span>
                        </div>
                        <div>
                          <span className="vote-count">
                            Uploaded by {video.user.userName}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
            ) : (
              <h3>No submissions yet. Be the first!</h3>
            )}
          </div>

          <h2 className="videos-title">All Videos</h2>
          <div className="video-grid">
            {videos && videos.length > 0 ? (
              videos.map((video) => {
                return (
                  <div key={video.id} className="video-card">
                    <FlagIcon
                      onClick={() => handleFlag(video)}
                      style={{ cursor: "pointer" }}
                      className="videoFlag"
                    />
                    <div className="video-embed-container">
                      <YouTubeEmbed
                        className="video-embed"
                        url={video.url}
                        width={windowWidth > 600 ? 640 : 250}
                        height={windowWidth > 600 ? 360 : 200}
                      />
                    </div>
                    <div className="video-info">
                      <div>
                        <button
                          className="vote-button"
                          onClick={() => {
                            const voteChange = video.votedByUser ? -1 : 1;
                            handleVote(video.id, voteChange);
                            video.votedByUser = !video.votedByUser;
                          }}
                        >
                          {video.votedByUser ? "Voted!" : "Vote"}
                        </button>
                        <span className="vote-count">
                          {video.votes || 0} vote(s)
                        </span>
                        <div>
                          <span className="vote-count">
                            Uploaded by {video.user.userName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h3 style={{ color: "#fff", textAlign: "center", width: "100%" }}>
                No submissions yet. Be the first!
              </h3>
            )}
          </div>
        </div>
      </Box>
    </>
  );
}

export default CommunityChallenges;
