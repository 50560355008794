import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import axios from "axios";
import qs from "qs";
import { db } from "../../firebase";
import { collection, addDoc } from "firebase/firestore";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Swal from "sweetalert2";
import UserContext from "../../UserContext";
import "./useissuechallenge.css";

const Step1 = ({ onSubmit }) => (
  <form
    onSubmit={onSubmit}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <h1
      style={{
        fontSize: "1.5rem",
        color: "#EAB308",
        fontFamily: "roboto",
        textAlign: "center",
        margin: "15px",
      }}
    >
      Issue a Challenge
    </h1>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Field
          name="challengeName"
          render={({ input, meta }) => (
            <TextField
              {...input}
              placeholder="Challenge Name"
              variant="outlined"
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
              sx={{
                bgcolor: "background.paper",
                margintop: "20px",
                marginBottom: "20px",
                marginLeft: "auto",
              }}
            />
          )}
        />
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Field
          name="challengeDescription"
          render={({ input, meta }) => (
            <TextField
              {...input}
              placeholder="Challenge Description"
              variant="outlined"
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
              sx={{ bgcolor: "background.paper" }}
            />
          )}
        />
      </div>
    </div>
    <Button
      type="submit"
      style={{
        width: "5vw",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
        backgroundColor: "#EAB308",
      }}
    >
      Next
    </Button>
  </form>
);

const CLIENT_ID = "9eb1d6b27b3b400490708c5c38030412";
const CLIENT_SECRET = "b4a4b69267cd463292b2403a3f4ac0e0";

const instruments = [
  "Any",
  "Guitar",
  "Piano",
  "Drums",
  "Bass",
  "Violin",
  "Saxophone",
  "Trumpet",
  "Trombone",
  "Clarinet",
  "Flute",
  "Oboe",
  "Bassoon",
  "French Horn",
  "Tuba",
  "Harmonica",
  "Accordion",
  "Banjo",
  "Mandolin",
  "Ukulele",
  "Harp",
  "Cello",
  "Viola",
  "Double Bass",
  "Electric Guitar",
  "Acoustic Guitar",
  "Classical Guitar",
  "Steel Guitar",
  "Slide Guitar",
  "Pedal Steel Guitar",
  "12-String Guitar",
  "Nylon String Guitar",
  "Steel String Guitar",
  "Electric Bass",
  "Acoustic Bass",
  "Fretless Bass",
  "Upright Bass",
  "Electric Piano",
  "Acoustic Piano",
  "Grand Piano",
  "Upright Piano",
  "Synthesizer",
  "Keyboard",
  "Organ",
  "Harpsichord",
  "Clavichord",
  "Digital Piano",
];

const styles = [
  "Any",
  "Rock",
  "Pop",
  "Jazz",
  "Classical",
  "Country",
  "Blues",
  "Folk",
  "Rap",
  "Hip-Hop",
  "R&B",
  "Reggae",
  "Electronic",
  "Dance",
  "Metal",
  "Punk",
  "Indie",
  "Alternative",
  "Funk",
  "Soul",
  "Gospel",
  "Latin",
  "World",
  "New Age",
  "Ambient",
  "Soundtrack",
  "Vocal",
  "Choral",
  "Orchestral",
  "Chamber",
  "Symphonic",
  "Concerto",
  "Sonata",
  "Suite",
  "Opera",
  "Operetta",
  "Musical",
  "Film Score",
  "TV Score",
  "Video Game Score",
  "Commercial",
  "Jingle",
  "Theme",
];

const Step2 = ({ onSubmit, previousStep, setSong }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [song, setLocalSong] = useState(null);
  const [songs, setSongs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleInputChange = debounce((value) => {
    searchSong(value);
  }, 500);

  useEffect(() => {
    const base64 = btoa(`${CLIENT_ID}:${CLIENT_SECRET}`);
    fetch("https://accounts.spotify.com/api/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${base64}`,
      },
      body: new URLSearchParams({
        grant_type: "client_credentials",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => setAccessToken(data.access_token))
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }, []);

  const searchSong = async (songName) => {
    const response = await fetch(
      `https://api.spotify.com/v1/search?type=track&limit=5&q=${encodeURIComponent(
        songName
      )}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data = await response.json();
    setSongs(data.tracks.items); // store all returned songs
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <h1
            style={{
              fontSize: "1.5rem",
              color: "#EAB308",
              fontFamily: "roboto",
              textAlign: "center",
              margin: "15px",
            }}
          >
            Select a song to cover
          </h1>
          <Field
            name="songName"
            render={({ input, meta }) => (
              <div>
                <TextField
                  {...input}
                  placeholder="Song Name"
                  variant="outlined"
                  error={meta.touched && meta.error}
                  helperText={meta.touched && meta.error}
                  sx={{ bgcolor: "background.paper", width: "100%" }}
                  onChange={(e) => {
                    input.onChange(e);
                    handleInputChange(e.target.value);
                    setDropdownVisible(true); // show the dropdown when the user types
                  }}
                />
                {dropdownVisible && (
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor: "white",
                        width: "100%",
                        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
                        zIndex: 1,
                        cursor: "pointer",
                      }}
                    >
                      {songs &&
                        songs.map((song, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              form.change(
                                "songName",
                                `${song.name} by ${song.artists[0].name}`
                              ); // change the value of the songName field
                              setSong(song);
                              setDropdownVisible(false); // hide the dropdown when a song is selected
                            }}
                          >
                            {song.name} by {song.artists[0].name}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              type="button"
              style={{
                width: "5vw",
                margin: "20px",
                backgroundColor: "#EAB308",
              }}
              onClick={
                typeof previousStep === "function" ? previousStep : undefined
              }
            >
              Back
            </Button>
            <Button
              type="submit"
              style={{
                width: "5vw",
                margin: "20px",
                backgroundColor: "#EAB308",
              }}
            >
              Next
            </Button>
          </div>
        </form>
      )}
    />
  );
};

const Step3 = ({ onSubmit, previousStep }) => (
  <form onSubmit={onSubmit}>
    <h1
      style={{
        fontSize: "1.5rem",
        color: "#EAB308",
        fontFamily: "roboto",
        textAlign: "center",
        margin: "15px",
      }}
    >
      Conditions
    </h1>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px",
          marginTop: "20px",
          width: "300px",
        }}
      >
        <Field
          name="instruments"
          render={({ input, meta }) => (
            <div>
              <Autocomplete
                multiple
                options={instruments}
                value={input.value || []}
                onChange={(event, newValue) => {
                  input.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Instruments"
                    placeholder="Choose instruments"
                    className="textField"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderTags={() => null}
              />
              <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                {Array.isArray(input.value) &&
                  input.value.map((option, index) => (
                    <Chip
                      sx={{
                        backgroundColor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                      }}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        const newValue = [...input.value];
                        newValue.splice(index, 1);
                        input.onChange(newValue);
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        />
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px",
          marginTop: "20px",
          width: "300px",
        }}
      >
        <Field
          name="styles"
          render={({ input, meta }) => (
            <div>
              <Autocomplete
                multiple
                options={styles}
                value={input.value || []}
                onChange={(event, newValue) => {
                  input.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Styles"
                    placeholder="Choose styles"
                    className="textField"
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{option}</li>
                )}
                renderTags={() => null}
              />
              <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                {Array.isArray(input.value) &&
                  input.value.map((option, index) => (
                    <Chip
                      sx={{
                        backgroundColor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                      }}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        const newValue = [...input.value];
                        newValue.splice(index, 1);
                        input.onChange(newValue);
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        />
      </div>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Field
          name="specialConditions"
          render={({ input, meta }) => (
            <div>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={input.value || []}
                onChange={(event, newValue) => {
                  input.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Special Conditions"
                    variant="outlined"
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error}
                    className="textField"
                  />
                )}
                renderTags={() => null}
              />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {Array.isArray(input.value) &&
                  input.value.map((option, index) => (
                    <Chip
                      sx={{
                        backgroundColor: "#EAB308",
                        color: "#1E3A8A",
                        margin: "10px 10px",
                      }}
                      variant="outlined"
                      label={option}
                      onDelete={() => {
                        const newValue = [...input.value];
                        newValue.splice(index, 1);
                        input.onChange(newValue);
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        />
      </div>
    </div>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Button
        type="button"
        style={{
          width: "5vw",
          margin: "20px",
          backgroundColor: "#EAB308",
        }}
        onClick={typeof previousStep === "function" ? previousStep : undefined}
      >
        Back
      </Button>
      <Button
        type="submit"
        style={{
          width: "5vw",
          margin: "20px",
          backgroundColor: "#EAB308",
        }}
      >
        Submit
      </Button>
    </div>
  </form>
);

const UseIssueChallenge = ({ setShowIssueChallenge }) => {
  const [page, setPage] = useState(1);
  const [accessToken, setAccessToken] = useState(null);
  const [song, setSong] = useState(null);
  const user = React.useContext(UserContext);

  console.log(user);

  const getAccessToken = async () => {
    const data = {
      grant_type: "client_credentials",
    };

    const auth = {
      username: "9eb1d6b27b3b400490708c5c38030412",
      password: "b4a4b69267cd463292b2403a3f4ac0e0",
    };

    const response = await axios.post(
      "https://accounts.spotify.com/api/token",
      qs.stringify(data),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        auth: auth,
      }
    );

    setAccessToken(response.data.access_token);
  };

  const onSubmit = async (values) => {
    if (page === 1) {
      await getAccessToken();
      setPage(page + 1);
    } else if (page < 3) {
      setPage(page + 1);
    } else {
      // Combine the form values with the song data
      const data = { ...values, song, user, date: new Date() };
      // Send the data to Firestore
      try {
        await addDoc(collection(db, "Challenges"), data);
        console.log("Document successfully written!");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: `New challenge created successfully!`,
          customClass: {
            container: "swal",
          },
        });
        setShowIssueChallenge(false);
      } catch (error) {
        console.error("Error writing document: ", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          customClass: {
            container: "swal",
          },
        });
      }
    }
  };

  return (
    <div className="issue-challenge-form">
      <Form
        className="issue-challenge-form"
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          if (page === 1) {
            return <Step1 onSubmit={handleSubmit} />;
          } else if (page === 2) {
            return (
              <Step2
                onSubmit={handleSubmit}
                previousStep={() => setPage(page - 1)}
                accessToken={accessToken}
                setSong={setSong}
              />
            );
          } else if (page === 3) {
            return (
              <Step3
                onSubmit={handleSubmit}
                previousStep={() => setPage(page - 1)}
              />
            );
          }
        }}
      />
    </div>
  );
};

export default UseIssueChallenge;
