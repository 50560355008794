import React, {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
} from "react";
import Navbar from "../../components/navbar/navbar";
import { gsap } from "gsap";
import ChallengeCard from "../../components/challengecard/challengecard";
import DownArrow from "../../components/arrows/downarrow";
import UpArrow from "../../components/arrows/uparrow";
import WeeklyChallengeCard from "../../components/weeklychallenge/weeklychallengecard";
import CommunityChallenges from "../../components/communitychallenges/communitychallenges";
import posterImage from "../../images/weeklychallengeposter.png";
import FeaturedVideos from "../../components/featurevideos/featurevideos";
import FeaturedMusician from "../../components/featuredmusicians/featuredmusicians";
import ScrollContainer from "react-indiana-drag-scroll";
import Chip from "@mui/material/Chip";
import UseIssueChallenge from "../../components/useissuechallenge/useissuechallenge";
import { Modal } from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import Swal from "sweetalert2";
import UserContext from "../../UserContext";
import { db } from "../../firebase";
import "./home.css";
import { Link } from "react-router-dom";

export default function Home() {
  const taglineRef = useRef();
  const subheadingRef = useRef();
  const ctaRef = useRef();
  const issueChallengeBtnRef = useRef();
  const acceptChallengeBtnRef = useRef();
  const watchEntriesBtnRef = useRef();
  const paragraphRef = useRef();
  const heroSectionRef = useRef();
  const secondSectionRef = useRef();
  const thirdSectionRef = useRef();
  const fourthSectionRef = useRef();
  const fifthSectionRef = useRef();
  const challengePromptRef = useRef();
  const [showIssueChallenge, setShowIssueChallenge] = useState(false);
  const [challenges, setChallenges] = useState([]);

  const user = useContext(UserContext);

  function handleIssueChallenge(e) {
    if (!user) {
      e.preventDefault();
      Swal.fire("Please log in to issue a challenge", "", "info");
    } else {
      setShowIssueChallenge(true);
    }
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Challenges"), (snapshot) => {
      const challengesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChallenges(challengesData);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  // Define a sample challenge here, or fetch it from an API
  const sampleChallenge = {
    title: "Challenge Name",
    description: "Challenge description goes here",
    endDate: "2023-04-07T00:00:00Z", // Example date; use the correct format
    requirements: ["Requirement 1", "Requirement 2", "Requirement 3"],
  };

  {
    /*const challenges = [
    {
      title: "Challenge 1",
      description: "This is a description for Challenge 1.",
    },
    {
      title: "Challenge 2",
      description: "This is a description for Challenge 2.",
    },
    // ... more challenges
  ];

  // Placeholder challenge data
  const challengeData = [
    {
      prompt: "A 90's boyband song as an acoustic ballad",
      user: "User1",
    },
    {
      prompt: "A classic rock song as a jazz standard",
      user: "User2",
    },
    {
      prompt: "A pop song as a country ballad",
      user: "User3",
    },
  ];

  const [currentChallenge, setCurrentChallenge] = useState(challengeData[0]);
*/
  }

  useEffect(() => {
    gsap.from(taglineRef.current, {
      duration: 1,
      y: -50,
      opacity: 0,
      ease: "power3.out",
      delay: 0.3,
    });

    gsap.from(subheadingRef.current, {
      duration: 1,
      y: 50,
      opacity: 0,
      ease: "power3.out",
      delay: 0.6,
    });

    /*   gsap.from(ctaRef.current, {
        duration: 1,
        scale: 0.5,
        opacity: 0,
        ease: 'elastic.out(1, 0.75)',
        delay: 1,
      });

      gsap.fromTo(issueChallengeBtnRef.current, 
        { scale: 0.5, opacity: 0 },
        { duration: 1, scale: 1, opacity: 1, ease: 'elastic.out(1, 0.75)', delay: 1.1 }
      );
    
      gsap.fromTo(acceptChallengeBtnRef.current, 
        { scale: 0.5, opacity: 0 },
        { duration: 1, scale: 1, opacity: 1, ease: 'elastic.out(1, 0.75)', delay: 1.3 }
      );
    
      gsap.fromTo(watchEntriesBtnRef.current, 
        { scale: 0.5, opacity: 0 },
        { duration: 1, scale: 1, opacity: 1, ease: 'elastic.out(1, 0.75)', delay: 1.5 }
      );   */
    {
      /*}
    // Create an interval to cycle through challenges
    const cycleChallenges = () => {
      setCurrentChallenge(challengeData[challengeIndex]);
      challengeIndex = (challengeIndex + 1) % challengeData.length;
    };

    // Set an interval to cycle challenges every 5 seconds
    const interval = setInterval(() => {
      // Animate the challenge prompt
      gsap.to(challengePromptRef.current, {
        x: "-110%",
        opacity: 0, // Add this line to make the text fade out
        duration: 1,
        onComplete: () => {
          cycleChallenges();
          gsap.set(challengePromptRef.current, { x: "110%", opacity: 1 }); // Reset the opacity back to 1
          gsap.to(challengePromptRef.current, { x: "0%", duration: 1 });
        },
      });
    }, 5000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  */
    }

    const backgroundImages = document.querySelectorAll(".background-image");
    const contentElements = [
      taglineRef,
      subheadingRef,
      ctaRef,
      issueChallengeBtnRef,
      acceptChallengeBtnRef,
      watchEntriesBtnRef,
      paragraphRef,
      heroSectionRef,
      secondSectionRef,
      thirdSectionRef,
      fourthSectionRef,
      fifthSectionRef,
      challengePromptRef,
    ];
  }, []);

  return (
    <>
      <Navbar />
      <body>
        <div ref={heroSectionRef} className="section hero-section">
          <div
            className="background-image"
            style={{ backgroundImage: "url('../../images/background8.png')" }}
          ></div>
          <h1 ref={taglineRef}>
            <strong>WE WANT IT THAT WAY</strong>
          </h1>
          <h2 ref={subheadingRef}>Unleash Your Creativity</h2>
          <br />
          <h2 ref={subheadingRef}>Take on a challenge, upload your version to your YouTube channel , and submit your entry to join the competition!</h2>
          <div className="hero-cta-buttons">
            <button
              ref={issueChallengeBtnRef}
              className="cta-button issue-challenge-btn"
              onClick={handleIssueChallenge}
            >
              Issue a Challenge
            </button>

            <Modal
              open={showIssueChallenge}
              onClose={() => setShowIssueChallenge(false)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto",
              }}
            >
              <div>
                <UseIssueChallenge
                  setShowIssueChallenge={setShowIssueChallenge}
                />
              </div>
            </Modal>
            <Link
              to="/challenges"
              style={{
                textDecoration: "none",
              }}
            >
              <button
                ref={acceptChallengeBtnRef}
                className="cta-button accept-challenge-btn"
                style={{}}
              >
                Browse Challenges
              </button>
            </Link>
            <Link
              to="/videos"
              style={{
                textDecoration: "none",
                marginLeft: "10px",
              }}
            >
              <button
                ref={watchEntriesBtnRef}
                className="cta-button watch-entries-btn"
              >
                Watch Videos
              </button>
            </Link>
          </div>
        </div>
        {/*}
        <div
          ref={secondSectionRef}
          id="second-section"
          className="section second-section"
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {challenges.map((challenge) => (
              <WeeklyChallengeCard key={challenge.id} challenge={challenge} />
            ))}
          </div>
          <div className="home-arrow-container">
            <UpArrow label="Back To Top" />
          </div>
          */}
      </body>
    </>
  );
}
