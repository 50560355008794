import React, {
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { gsap, Power2 } from "gsap";
import { auth, db } from "../../firebase";
import Swal from "sweetalert2";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  doc,
  setDoc,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { sendPasswordResetEmail } from "firebase/auth";
import UserContext from "../../UserContext";
import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  AppBar,
} from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import "./navbar.css";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Navbar() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userName } = user || {};
  const bar1 = useRef();
  const bar2 = useRef();
  const bar3 = useRef();
  const [isClicked, setIsClicked] = useState(false);
  const [menuBar] = useState(gsap.timeline({ paused: true }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  useEffect(() => {
    menuBar
      .to(
        bar1.current,
        {
          attr: { d: "M8,2 L2,8" },
          x: 1,
          ease: Power2.easeInOut,
        },
        "start"
      )
      .to(
        bar2.current,
        {
          autoAlpha: 0,
        },
        "start"
      )
      .to(
        bar3.current,
        {
          attr: { d: "M8,8 L2,2" },
          x: 1,
          ease: Power2.easeInOut,
        },
        "start"
      )
      .to(".fullpage-menu", {
        duration: 0,
        display: "block",
        ease: "Expo.easeInOut",
      })
      .from(".menu-bg span", {
        duration: 1,
        x: "100%",
        stagger: 0.1,
        ease: "Expo.easeInOut",
      })
      .from(
        ".main-menu li a",
        {
          duration: 1.5,
          y: "100%",
          stagger: 0.2,
          ease: "Expo.easeInOut",
        },
        "-=0.5"
      )
      .from(
        ".social-links li",
        {
          duration: 1,
          y: "-100%",
          opacity: 0,
          stagger: 0.1,
          ease: "Expo.easeInOut",
        },
        "-=0.5"
      )
      .reverse();
  }, []);

  const toggleMenuTimeline = () => {
    menuBar.reversed(!menuBar.reversed());
  };

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) =>
      setIsSignedIn(!!user)
    );
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const openSignInForm = () => {
    Swal.fire({
      title: "Sign In",
      html: `
        <input type="text" id="email" class="swal2-input" placeholder="Email">
        <input type="password" id="password" class="swal2-input" placeholder="Password">
        <div><a href="javascript:void(0);" id="forgot-password" style="text-decoration: underline;">Forgot Password?</a></div>
      `,
      confirmButtonText: "Sign In",
      focusConfirm: false,
      preConfirm: () => {
        const email = Swal.getPopup().querySelector("#email").value;
        const password = Swal.getPopup().querySelector("#password").value;

        if (!email || !password) {
          Swal.showValidationMessage(`Please enter email and password`);
        }

        return { email, password };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        signInWithEmailAndPassword(
          auth,
          result.value.email,
          result.value.password
        )
          .then((userCredential) => {
            console.log("User signed in");
          })
          .catch((error) => {
            // Handle Errors here.
            console.error("Error signing in: ", error);
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      }
    });
    // Add event listener outside of Swal
    document.getElementById("forgot-password").addEventListener("click", () => {
      Swal.fire({
        title: "Enter your email address",
        input: "email",
        inputPlaceholder: "Your email address",
        confirmButtonText: "Send Reset Link",
        preConfirm: (email) => {
          sendPasswordResetEmail(auth, email)
            .then(() => {
              // Password reset email sent
              Swal.fire({
                title: "Success!",
                text: "Password reset email has been sent!",
                icon: "success",
                confirmButtonText: "OK",
              });
            })
            .catch((error) => {
              // Handle Errors
              Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        },
      });
    });
  };

  const openSignInPrompt = () => {
    Swal.fire({
      title: "Please sign in",
      text: "Choose an option below.",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Sign In",
      cancelButtonText: "Sign Up",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        openSignInForm(); // Call the function to open Sign In form
      } else if (result.isDismissed) {
        openSignUpForm(); // Call the function to open Sign Up form
      }
    });
  };

  const openSignUpForm = () => {
    Swal.fire({
      title: "Sign Up",
      html: `
      <input type="text" id="username" class="swal2-input" placeholder="Username" style="font-size: 14px;">
      <input type="text" id="email" class="swal2-input" placeholder="Email" style="font-size: 14px;">
      <input type="password" id="password" class="swal2-input" placeholder="Password" style="font-size: 14px;">
      <input type="text" id="aboutUser" class="swal2-input" placeholder="About You" style="font-size: 14px;">
      <input type="text" id="facebook" class="swal2-input" placeholder="Facebook" style="font-size: 14px;">
      <input type="text" id="instagram" class="swal2-input" placeholder="Instagram" style="font-size: 14px;">
      <input type="text" id="youtube" class="swal2-input" placeholder="YouTube" style="font-size: 14px;">
      <input type="text" id="tiktok" class="swal2-input" placeholder="TikTok" style="font-size: 14px;">
      <input type="text" id="x" class="swal2-input" placeholder="X" style="font-size: 14px;">
      `,
      confirmButtonText: "Sign Up",
      focusConfirm: false,
      preConfirm: async () => {
        const userName = Swal.getPopup().querySelector("#username").value;
        const email = Swal.getPopup().querySelector("#email").value;
        const password = Swal.getPopup().querySelector("#password").value;
        const aboutUser = Swal.getPopup().querySelector("#aboutUser").value;
        const facebook = Swal.getPopup()
          .querySelector("#facebook")
          .value.toLowerCase();
        const instagram = Swal.getPopup()
          .querySelector("#instagram")
          .value.toLowerCase();
        const youtube = Swal.getPopup()
          .querySelector("#youtube")
          .value.toLowerCase();
        const tiktok = Swal.getPopup()
          .querySelector("#tiktok")
          .value.toLowerCase();
        const x = Swal.getPopup().querySelector("#x").value.toLowerCase();

        if (!userName || !email || !password) {
          Swal.showValidationMessage(
            `Please enter username, email, and password`
          );
        }

        // Check if the username already exists in Firestore
        const checkUsernameExists = async (userName) => {
          const usersCollection = collection(db, "Users");
          const q = query(usersCollection, where("userName", "==", userName));
          const snapshot = await getDocs(q);
          return !snapshot.empty;
        };

        const usernameExists = await checkUsernameExists(userName);

        if (usernameExists) {
          Swal.fire({
            title: "Error!",
            text: "The username is already in use. Please choose a different one.",
            icon: "error",
            confirmButtonText: "OK",
          });
          return null; // Returning null prevents the form from closing
        }

        return {
          userName,
          email,
          password,
          aboutUser,
          facebook,
          instagram,
          youtube,
          tiktok,
          x,
        };
      },
    }).then(async (result) => {
      if (result.isConfirmed && result.value) {
        try {
          // Create a new user with email and password using Firebase Authentication
          const userCredential = await createUserWithEmailAndPassword(
            auth,
            result.value.email,
            result.value.password
          );

          const user = userCredential.user;
          console.log(user);

          // Save user data to Firestore
          await saveUserDataToFirestore(
            user.uid,
            result.value.userName,
            result.value.email,
            result.value.aboutUser,
            result.value.facebook,
            result.value.instagram,
            result.value.youtube,
            result.value.tiktok,
            result.value.x
          );

          // If everything is successful
          Swal.fire({
            title: "Success!",
            text: "User created successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } catch (error) {
          // Handle errors
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const saveUserDataToFirestore = async (
    uid,
    userName,
    email,
    aboutUser,
    facebook,
    instagram,
    youtube,
    tiktok,
    x
  ) => {
    console.log("saveUserDataToFirestore started");
    const batch = writeBatch(db);

    // Create an empty User document with basic info and empty teams array
    console.log("Creating user document");
    await setDoc(doc(collection(db, "Users"), uid), {
      uid,
      userName,
      email,
      type: "User",
      aboutUser,
      facebook,
      instagram,
      youtube,
      tiktok,
      x,
      createdAt: serverTimestamp(),
    });
    console.log("User document created");

    console.log("Committing batch");
    await batch.commit();
    console.log("Batch committed");
  };

  return (
    <HideOnScroll>
      <AppBar>
        <header>
          <div className="header-row">
            <Link to="/" className="linkStyle">
              <div className="brand-logo">
                <h1 className="navbar-logo">Home</h1>
              </div>
            </Link>
            {!isSignedIn ? (
              <Button
                className="header-text linkStyle"
                onClick={openSignInPrompt}
                sx={{
                  color: "#EAB308",
                  textDecoration: "none !important",
                }}
              >
                Login
              </Button>
            ) : (
              <>
                <Button
                  className="header-text"
                  onClick={handleClick}
                  sx={{
                    color: "#EAB308 !important",
                    textDecoration: "none !important",
                  }}
                >
                  {userName ? userName : <CircularProgress size={24} />}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleSignOut} sx={{ color: "#EAB308" }}>
                    Sign Out
                  </MenuItem>
                </Menu>
              </>
            )}
          </div>
        </header>
      </AppBar>
    </HideOnScroll>
  );
}
