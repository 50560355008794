import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../components/navbar/navbar";
import UseIssueChallenge from "../../components/useissuechallenge/useissuechallenge";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import WeeklyChallengeCard from "../../components/weeklychallenge/weeklychallengecard";
import { YouTubeEmbed } from "react-social-media-embed";
import Swal from "sweetalert2";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
import "./video.css";

function Videos() {
  const [videos, setVideos] = useState([]);
  const user = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Videos"), (snapshot) => {
      const videoData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVideos(videoData);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Navbar />
      <div>
        <div className="video-grid">
          {videos.map((video) => {
            return (
              <div
                style={{ backgroundColor: "#1E3A8A" }}
                key={video.id}
                className="video-card"
              >
                <div className="video-embed-container">
                  <YouTubeEmbed
                    className="video-embed"
                    url={video.url}
                    width={windowWidth > 600 ? 640 : 250}
                    height={windowWidth > 600 ? 360 : 200}
                  />
                </div>
                <div className="video-info">
                  <div>
                    <span style={{ color: "#EAB308" }} className="vote-count">
                      Uploaded by {video.user.userName}
                    </span>
                  </div>
                    <Link to={`/communitychallenge/${video.challengeId}`}>
                      <span style={{ color: "#EAB308" }} className="vote-count">
                        {video.challengeName} Challenge
                      </span>
                    </Link>
                  </div>
                </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Videos;
