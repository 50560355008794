import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import "./weeklychallengecard.css";
import { Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";

export default function WeeklyChallenges({ challenge }) {
  return (
    <div className="challengeCard">
      <Paper elevation={3} className="challengesCard">
        <Box className="challengeCardBox">
          <h1 style={{ color: "#EAB308" }} className="challenge-name">
            {challenge.challengeName}
          </h1>
          <div>
            <span style={{ color: "#EAB308" }}>
              Submitted by {challenge.user && challenge.user.userName} on{" "}
              {challenge.date.toDate().toLocaleDateString()}
            </span>
          </div>
          <div className="challengeCardContent">
            <p className="challengeCardDescription">
              {challenge.challengeDescription}
            </p>

            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 1,
                borderColor: "#000000",
                width: "90%",
              }}
            />

            <Typography variant="h6" className="challengeCardRequirements">
              Original Song
            </Typography>

            {challenge.song && challenge.song.id && (
              <iframe
                style={{ borderRadius: "5px", margin: "20px" }}
                src={`https://open.spotify.com/embed/track/${challenge.song.id}`}
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            )}

            <Typography variant="h6" className="challengeCardRequirements">
              Criteria
            </Typography>

            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 1,
                borderColor: "#000000",
                width: "90%",
              }}
            />
            <Typography variant="h6" className="challengeCardRequirements">
              Instruments
            </Typography>
            <div className="challengeChips">
              {challenge.instruments && challenge.instruments.length > 0 ? (
                Array.isArray(challenge.instruments) &&
                challenge.instruments.map((instrument) => (
                  <Chip
                    key={instrument}
                    label={instrument}
                    deleteIcon={<DeleteIcon />}
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                ))
              ) : (
                <Chip
                  label="Any Instrument"
                  sx={{
                    bgcolor: "#EAB308",
                    color: "#1E3A8A",
                    margin: "10px 10px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                  }}
                />
              )}
            </div>

            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 1,
                borderColor: "#000000",
                width: "90%",
              }}
            />

            <Typography variant="h6" className="challengeCardRequirements">
              Style
            </Typography>
            <div className="challengeChips">
              {challenge.styles && challenge.styles.length > 0 ? (
                Array.isArray(challenge.styles) &&
                challenge.styles.map((style) => (
                  <Chip
                    key={style}
                    label={style}
                    deleteIcon={<DeleteIcon />}
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                ))
              ) : (
                <Chip
                  label="Any Style"
                  sx={{
                    bgcolor: "#EAB308",
                    color: "#1E3A8A",
                    margin: "10px 10px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                  }}
                />
              )}
            </div>

            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 1,
                borderColor: "#000000",
                width: "90%",
              }}
            />
            <Typography variant="h6" className="challengeCardRequirements">
              Conditions
            </Typography>
            <div className="challengeChips">
              {challenge.conditions && challenge.conditions.length > 0 ? (
                Array.isArray(challenge.conditions) &&
                challenge.conditions.map((condition) => (
                  <Chip
                    key={condition}
                    label={condition}
                    deleteIcon={<DeleteIcon />}
                    sx={{
                      bgcolor: "#EAB308",
                      color: "#1E3A8A",
                      margin: "10px 10px",
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                    }}
                  />
                ))
              ) : (
                <Chip
                  label="No Conditions"
                  sx={{
                    bgcolor: "#EAB308",
                    color: "#1E3A8A",
                    margin: "10px 10px",
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
                  }}
                />
              )}
            </div>
            <hr
              style={{
                color: "#000000",
                backgroundColor: "#000000",
                height: 1,
                borderColor: "#000000",
                width: "90%",
              }}
            />
          </div>

          <Link
            to={`/communitychallenge/${challenge.id}`}
            className="challengeCardLink"
          >
            <button
              style={{
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 1)",
              }}
              className="challengeCardButton"
            >
              View
            </button>
          </Link>
        </Box>
      </Paper>
    </div>
  );
}
