import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../components/navbar/navbar";
import UseIssueChallenge from "../../components/useissuechallenge/useissuechallenge";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import WeeklyChallengeCard from "../../components/weeklychallenge/weeklychallengecard";
import Swal from "sweetalert2";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";
import "./challenges.css";

function Challenges() {
  const [showIssueChallenge, setShowIssueChallenge] = useState(false);
  const [challenges, setChallenges] = useState([]);
  const user = useContext(UserContext);

  function handleIssueChallenge(e) {
    if (!user) {
      e.preventDefault();
      Swal.fire("Please log in to issue a challenge", "", "info");
    } else {
      setShowIssueChallenge(true);
    }
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Challenges"), (snapshot) => {
      const challengesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChallenges(challengesData);
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="challenges-bg">
        <Navbar />
        <div className="challenge-section">
          <Link to="/challenges">
            <button
              className="cta-button issuechallengebtn"
              onClick={handleIssueChallenge}
              style={{
                borderWidth: "2px",
                borderColor: "#FFD700",
                borderStyle: "solid",
                color: "#FFD700",
              }}
            >
              Issue a Challenge
            </button>
          </Link>
          <div className="challengeGrid">
            {challenges.map((challenge) => (
              <WeeklyChallengeCard
                className="challengeCard"
                key={challenge.id}
                challenge={challenge}
              />
            ))}
          </div>
          <Modal
            open={showIssueChallenge}
            onClose={() => setShowIssueChallenge(false)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "auto",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            }}
          >
            <div>
              <UseIssueChallenge
                setShowIssueChallenge={setShowIssueChallenge}
              />
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Challenges;
