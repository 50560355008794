import React, { useState, useEffect } from "react";
import "./userprofile.css";
import {
  Avatar,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  MobileStepper,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import { YouTubeEmbed } from "react-social-media-embed";
import VideoThumbnailCard from "../../components/videothumbnailcard/videothumbnailcard";
import Facebook from "../../images/facebook.png";
import Instagram from "../../images/instagram.svg";
import TikTok from "../../images/tiktok.svg";
import X from "../../images/x.png";
import YouTube from "../../images/youtube.png";
import {
  Timestamp,
  doc,
  getDoc,
  addDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../firebase";
import WeeklyChallengeCard from "../../components/weeklychallenge/weeklychallengecard";
import Navbar from "../../components/navbar/navbar";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ minHeight: "350px" }} // Add this line
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const UserProfile = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [userData, setUserData] = useState(null);
  const [userVideos, setUserVideos] = useState([]);
  const [badges, setBadges] = useState([]);
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 600px)"); // Set the breakpoint based on your design
  const [openLightbox, setOpenLightbox] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [lightboxContent, setLightboxContent] = useState(null);
  const [userChallenges, setUserChallenges] = useState([]);

  const userName = "John Doe";
  const userPoints = 1234; // You can replace this with actual data

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenLightbox = (video) => {
    setLightboxContent(video);
    setOpenLightbox(true);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const userDoc = await getDoc(doc(db, "Users", id));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      } else {
        console.log("No such document!");
      }
    };

    const fetchUserVideos = async () => {
      const q = query(collection(db, "Videos"), where("user.uid", "==", id));
      const querySnapshot = await getDocs(q);
      setUserVideos(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    const fetchUserChallenges = async () => {
      const q = query(
        collection(db, "Challenges"),
        where("user.uid", "==", id)
      );
      const querySnapshot = await getDocs(q);
      setUserChallenges(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    };

    fetchUserChallenges();
    fetchUserData();
    fetchUserVideos();
  }, [id]);

  return (
    <div className="user-profile-container">
      <div className="user-profile">
        <Navbar />
        {userData ? (
          <>
            <div className="avatar-container">
              {/*
              <Avatar
                className="profile-avatar"
                alt="User Name"
                src={userData.userImage}
              />
        */}
              <Typography
                variant="h5"
                className="user-name"
                style={{ color: "#F9C80E" }}
              >
                {userData.userName}
              </Typography>
              <div style={{ backgroundColor: "#fff", borderRadius: "5%" }}>
                {userData.facebook && (
                  <IconButton
                    href={userData.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={Facebook}
                      alt="Facebook"
                    />
                  </IconButton>
                )}
                {userData.instagram && (
                  <IconButton
                    href={userData.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={Instagram}
                      alt="Instagram"
                    />
                  </IconButton>
                )}
                {userData.tiktok && (
                  <IconButton
                    href={userData.tiktok}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={TikTok}
                      alt="TikTok"
                    />
                  </IconButton>
                )}
                {userData.youtube && (
                  <IconButton
                    href={userData.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={YouTube}
                      alt="YouTube"
                    />
                  </IconButton>
                )}
                {userData.x && (
                  <IconButton
                    href={userData.x}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ width: "24px", height: "24px" }}
                      src={X}
                      alt="X"
                    />
                  </IconButton>
                )}
              </div>
            </div>
            <div style={{ width: "auto" }}>
              <div className="menu-container">
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="User profile tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab style={{ color: "#F9C80E" }} label="Videos" />
                  <Tab style={{ color: "#F9C80E" }} label="Challenges" />
                  <Tab style={{ color: "#F9C80E" }} label="About artist" />
                </Tabs>
              </div>
              <TabPanel
                style={{ width: "100%", alignItems: "center" }}
                value={tabValue}
                index={0}
              >
                {userVideos.length === 0 ? (
                  <Typography>No videos found.</Typography>
                ) : (
                  <div className="user-video-grid">
                    {userVideos.map((video) => {
                      return (
                        <div
                          style={{ backgroundColor: "#1E3A8A" }}
                          key={video.id}
                          className="user-video-card"
                        >
                          <div className="video-embed-container">
                            <YouTubeEmbed
                              className="video-embed"
                              url={video.url}
                              width={windowWidth > 600 ? 450 : 250}
                              height={windowWidth > 600 ? 360 : 200}
                            />
                          </div>
                          <div className="video-info">
                            <div>
                              <span
                                style={{ color: "#EAB308" }}
                                className="vote-count"
                              >
                                Uploaded by {video.user.userName}
                              </span>
                            </div>
                            <Link
                              to={`/communitychallenge/${video.challengeId}`}
                            >
                              <span
                                style={{ color: "#EAB308" }}
                                className="vote-count"
                              >
                                {video.challengeName} Challenge
                              </span>
                            </Link>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div className="challengeGrid">
                  {userChallenges.map((challenge) => (
                    <WeeklyChallengeCard
                      className="challengeCard"
                      key={challenge.id}
                      challenge={challenge}
                    />
                  ))}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Typography style={{ color: "#F9C80E" }}>
                  {userData.aboutUser}
                </Typography>
              </TabPanel>
            </div>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>
      <Dialog
        open={openLightbox}
        onClose={() => setOpenLightbox(false)}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
          },
        }}
      >
        {lightboxContent && (
          <React.Fragment>
            <iframe
              title={lightboxContent.title}
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${lightboxContent.videoID}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                borderRadius: "12px",
              }}
            ></iframe>
            <Box m={2}>
              <Typography variant="h6">{lightboxContent.title}</Typography>
            </Box>
          </React.Fragment>
        )}
      </Dialog>
    </div>
  );
};

export default UserProfile;
